/**
 * Una incrustación de YouTube liviana, aqui estamos cargando una imagen miniatura de youtube, para no cargar todos los recursos del video y no se consuman recursos al primer loading.
 */

// Al incluir el siguiente comentario, le estamos diciendo al linter lo siguiente: "No me avises de que YT no está definido; asumo que es una variable global".
/* global YT */
class LiteYTEmbed extends HTMLElement {
    connectedCallback() {
        this.videoId = this.getAttribute('videoid');

        let playBtnEl = this.querySelector('.lty-playbtn');
        // A label for the button takes priority over a [playlabel] attribute on the custom-element
        this.playLabel = (playBtnEl && playBtnEl.textContent.trim()) || this.getAttribute('playlabel') || 'Play';

        this.dataset.title = this.getAttribute('title') || "";

        /**
         * Cargando miniatura de video youtube.)
         *
         * See https://github.com/paulirish/lite-youtube-embed/blob/master/youtube-thumbnail-urls.md
         */
        if (!this.style.backgroundImage) {
            this.style.backgroundImage = `url("https://i.ytimg.com/vi/${this.videoId}/maxresdefault.jpg")`;
             this.upgradePosterImage();
        }

        // Configurando el botón de reproducción y su etiqueta oculta visualmente
        if (!playBtnEl) {
            playBtnEl = document.createElement('button');
            playBtnEl.type = 'button';
            playBtnEl.classList.add('lty-playbtn');
            this.append(playBtnEl);
        }
        if (!playBtnEl.textContent) {
            const playBtnLabelEl = document.createElement('span');
            playBtnLabelEl.className = 'lyt-visually-hidden';
            playBtnLabelEl.textContent = this.playLabel;
            playBtnEl.append(playBtnLabelEl);
        }

        this.addNoscriptIframe();

        // for the PE pattern, change anchor's semantics to button
        if (playBtnEl.nodeName === 'A') {
            playBtnEl.removeAttribute('href');
            playBtnEl.setAttribute('tabindex', '0');
            playBtnEl.setAttribute('role', 'button');
            // fake button needs keyboard help
            playBtnEl.addEventListener('keydown', e => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    this.activate();
                }
            });
        }

        // On hover (or tap), warm up the TCP connections we're (likely) about to use.
        this.addEventListener('pointerover', LiteYTEmbed.warmConnections, { once: true });
        this.addEventListener('focusin', LiteYTEmbed.warmConnections, { once: true });

        // Una vez que el usuario hace clic, agrega el iframe para reproducir
        this.addEventListener('click', this.activate);

        // Chrome y Edge para escritorio no tienen problemas con la inserción básica de YouTube con ?autoplay=1
        // Sin embargo, Safari para escritorio y la mayoría de los navegadores móviles no rastrean correctamente el gesto del usuario de hacer clic durante la creación/carga del iframe,
        // por lo que no se reproducen automáticamente. En su lugar, debemos cargar 2 archivos JS secuenciales adicionales (1 KB + 165 KB) (un-br) para la API de YT Player
        // TODO: Intenta cargar la API de YT en paralelo con nuestro iframe y luego adjuntarlo/reproducirlo. #82
        this.needsYTApi = this.hasAttribute("js-api") || navigator.vendor.includes('Apple') || navigator.userAgent.includes('Mobi');
    }

    /**
     * Add a <link rel={preload | preconnect} ...> to the head
     */
    static addPrefetch(kind, url, as) {
        const linkEl = document.createElement('link');
        linkEl.rel = kind;
        linkEl.href = url;
        if (as) {
            linkEl.as = as;
        }
        document.head.append(linkEl);
    }

    /**
     * Comenzar a preconectar para calentar la carga del iframe
     * Dado que las solicitudes de red de la incrustación se cargan dentro de su iframe,
     * precargarlas fuera del iframe solo provocará descargas dobles.
     * Por lo tanto, lo mejor que podemos hacer es calentar algunas conexiones a los orígenes que se encuentran en la ruta crítica.
     */
    static warmConnections() {
        if (LiteYTEmbed.preconnected) return;
        LiteYTEmbed.addPrefetch('preconnect', 'https://www.youtube-nocookie.com');
        LiteYTEmbed.preconnected = true;
    }

    fetchYTPlayerApi() {
        // Comprobar si la API de YouTube ya está cargada y disponible en el contexto de la ventana (window)
        if (window.YT || (window.YT && window.YT.Player)) return;

        // Este fragmento de código asegura que la API de YouTube esté completamente cargada antes de que cualquier código dependiente intente utilizarla.
        this.ytApiPromise = new Promise((res, rej) => {
            var el = document.createElement('script');
            el.src = 'https://www.youtube.com/iframe_api';
            el.async = true;
            el.onload = _ => {
                YT.ready(res);
            };
            el.onerror = rej;
            this.append(el);
        });
    }

    async addYTPlayerIframe(param) {
        this.fetchYTPlayerApi();
        await this.ytApiPromise;

        const videoPlaceholderEl = document.createElement('div')
        this.append(videoPlaceholderEl);

        const paramsObj = Object.fromEntries(this.getParams().entries());

        this.playerPromise = new Promise(resolve => {
            let player = new YT.Player(videoPlaceholderEl, {
                width: '100%',
                videoId: this.videoId,
                playerVars: paramsObj,
                events: {
                    'onReady': event => {
                        event.target.playVideo();
                        resolve(player);
                    }
                }
            });
        });
    }

    // Add the iframe within <noscript> for indexability discoverability. See https://github.com/paulirish/lite-youtube-embed/issues/105
    addNoscriptIframe() {
        const iframeEl = this.createBasicIframe();
        const noscriptEl = document.createElement('noscript');
        // Appending into noscript isn't equivalant for mysterious reasons: https://html.spec.whatwg.org/multipage/scripting.html#the-noscript-element
        noscriptEl.innerHTML = iframeEl.outerHTML;
        this.append(noscriptEl);
    }

    getParams() {
        const params = new URLSearchParams(this.getAttribute('params') || []);
        params.append('autoplay', '1');
        params.append('playsinline', '1');
        return params;
    }

    async activate() {
        if (this.classList.contains('lyt-activated')) return;
        this.classList.add('lyt-activated');

        if (this.needsYTApi) {
            return this.addYTPlayerIframe(this.getParams());
        }

        const iframeEl = this.createBasicIframe();
        this.append(iframeEl);

        // Set focus for a11y
        iframeEl.focus();
    }

    /**
     * Creo iframe para reproducir video
     */
    createBasicIframe() {
        const iframeEl = document.createElement('iframe');
        iframeEl.width = 560;
        iframeEl.height = 315;
        iframeEl.title = this.playLabel;
        iframeEl.allow = 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture';
        iframeEl.allowFullscreen = true;
        //Codifico url por temas de seguridad y obtengo parametros de reproduccion
        iframeEl.src = `https://www.youtube-nocookie.com/embed/${encodeURIComponent(this.videoId)}?${this.getParams().toString()}`;
        return iframeEl;
    }

    /**
     * Siguiendo el espíritu del atributo `lowsrc` y los JPEG progresivos, actualizaremos la imagen confiable
     * del póster a una de mayor resolución, si está disponible.
     * Curiosamente, este archivo webp sddefault suele tener un tamaño de archivo menor, pero lo intentaremos de todos modos en segundo lugar
     * porque poner _una_ imagen frente al usuario es nuestra primera prioridad.
     *
     * See https://github.com/paulirish/lite-youtube-embed/blob/master/youtube-thumbnail-urls.md for more details
     */
     upgradePosterImage() {
         // Defer to reduce network contention.
         setTimeout(() => {
             const webpUrl = `https://i.ytimg.com/vi_webp/${this.videoId}/maxresdefault.webp`;
             const img = new Image();
             img.fetchPriority = 'low'; // low priority to reduce network contention
             img.referrerpolicy = 'origin'; // Not 100% sure it's needed, but https://github.com/ampproject/amphtml/pull/3940
             img.src = webpUrl;
             img.onload = e => {
                 // A pretty ugly hack since onerror won't fire on YouTube image 404. This is (probably) due to
                 // Youtube's style of returning data even with a 404 status. That data is a 120x90 placeholder image.
                 // … per "annoying yt 404 behavior" in the .md
                 const noAvailablePoster = e.target.naturalHeight == 90 && e.target.naturalWidth == 120;
                 if (noAvailablePoster) return;

                 this.style.backgroundImage = `url("${webpUrl}")`;
             }
         }, 100);
     }
}

customElements.define('lite-youtube', LiteYTEmbed);