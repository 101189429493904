import React from 'react';
import { URLImgFront } from '../config/URLDB';

class Documentos extends React.Component {
    render() {
        debugger;
        let ruta;
        if (!this.props.match.params.ruta1) {
            window.close();
        }
        else {
            if (this.props.match.params.ruta2 === '0' || !this.props.match.params.ruta2) {
                ruta = this.props.match.params.ruta1;
            }
            else {
                ruta = this.props.match.params.ruta1 + '/' + this.props.match.params.ruta2;
            }
        }

        return (
            <React.Fragment>
                <div style={{position: 'absolute', width: '100%', height:'100%'}} >
                    {/* <embed src={`${URLImgFront}/assets/Archivos/${this.props.match.params.ruta}#toolbar=0`} onPaste="return false" width='100%' height='100%'></embed> */}
                    {/*<div style={{position: 'absolute', width: '99%', height:'99%' }}></div>*/}
                    {/*<div style={{position: 'absolute', width: '99%', height:'99%' }}></div>*/}
                    {/*<div style={{position: 'absolute', width: '99%', height:'99%' }}></div>*/}
                    {/*<div style={{position: 'absolute', width: '99%', height:'99%' }}></div>*/}
                    {/*<iframe id="IframeDocumento" title="Documento" src={`${URLImgFront}/assets/Archivos/${this.props.match.params.ruta}#toolbar=0`} width="100%" height="100%"></iframe>*/}
                    <iframe id="IframeDocumento" title="Documento" src={`${URLImgFront}/assets/Archivos/${ruta}#toolbar=0`} width="100%" height="100%"></iframe>
                </div>
            </React.Fragment>
        );
    }
}

export default Documentos;